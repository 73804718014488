import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import MonthlyFuelComparison from '../CostCharts/MonthlyFuelComparison/MonthlyFuelComparison';
import FuelRangeChart from '../../../components/CostsCharts/FuelRangeChart';
import CostToOwnComparison from '../CostCharts/CostToOwnComparison';
import CostOfOwnershipOptions from '../../../components/CostOfOwnershipOptions/CostOfOwnershipOptions';
import { FormatCarName } from './../../../utils/Helpers/Format';
import {
  SALES_TAX,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
} from '../../../client_customizations/data/assumptions/ASSUMPTIONS';

import { FormattedMessage, useIntl } from 'react-intl';
import BreakevenChart from '../../../components/CostsCharts/BreakevenChart';

import ToolTip from '../../../components/shared/ToolTip/ToolTip';

import './CostOfOwnership.scss';

const CostOfOwnership = ({ cars, title, comparison, usedEv, location }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const [isLoading, setIsLoading] = useState(true);

  const [insuranceDataApi , setInsuranceData] = useState(null);
  const [maintenanceDataApi , setMaintenanceData] = useState(null);
  const [cache, setCache] = useState({ insurance: {}, maintenance: {} });

  useEffect(() => {
    const loadInsuranceData = async (vehicle_handle) => {
      if (cache.insurance[vehicle_handle]) {
        return { [vehicle_handle]: cache.insurance[vehicle_handle] };
      }
  
      const params = {
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/insurance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
          },
        });
        if (response.status === 200) {
          const insuranceData = await response.json();
          setCache((prevCache) => ({
            ...prevCache,
            insurance: {
              ...prevCache.insurance,
              [vehicle_handle]: insuranceData.yearly_insurances,
            },
          }));
          return { [vehicle_handle]: insuranceData.yearly_insurances };
        }
      } catch (error) {
        console.log('Failed to load insurance data for', vehicle_handle);
        console.log(error);
      }
      return null;
    };
  
    const loadMaintenanceData = async (vehicle_handle) => {
      if (cache.maintenance[vehicle_handle]) {
        return { [vehicle_handle]: cache.maintenance[vehicle_handle] };
      }
  
      const params = {
        annualmiles: userPrefs.get('milesDrivenAnnually'),
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/maintenance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
          },
        });
        if (response.status === 200) {
          const maintenanceData = await response.json();
          setCache((prevCache) => ({
            ...prevCache,
            maintenance: {
              ...prevCache.maintenance,
              [vehicle_handle]: maintenanceData.maintenance.costs,
            },
          }));
          return { [vehicle_handle]: maintenanceData.maintenance.costs };
        }
      } catch (error) {
        console.log('Failed to load maintenance data for', vehicle_handle);
        console.log(error);
      }
      return null;
    };
  
    const loadAllData = async () => {
      setIsLoading(true);
      const insuranceResults = {};
      const maintenanceResults = {};
  
      for (let car of cars) {
        const insuranceData = await loadInsuranceData(car.handle);
        const maintenanceData = await loadMaintenanceData(car.handle);
  
        if (insuranceData) {
          insuranceResults[car.handle] = insuranceData[car.handle];
        }
        if (maintenanceData) {
          maintenanceResults[car.handle] = maintenanceData[car.handle];
        }
      }
  
      setInsuranceData(insuranceResults);
      setMaintenanceData(maintenanceResults);
      setIsLoading(false);
    };
  
    if (cars && cars.length > 0) {
      loadAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  const car = cars[0];
  const equivalentGasVehicle = cars[1];

  SALES_TAX.title = intl.formatMessage({
    id: 'assumption.salesTax',
    defaultMessage: 'Sales Tax',
  });
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({
    id: 'assumption.gasolinePrice',
    defaultMessage: 'Price of Gasoline',
  });
  DOWN_PAYMENT.title = intl.formatMessage({
    id: 'assumption.downPayment',
    defaultMessage: 'Down Payment',
  });
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({
    id: 'assumption.downPaymentValue',
    defaultMessage: '10% of MSRP + Tax',
  });
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({
    id: 'assumption.electricityRate',
    defaultMessage: 'Electricity Rate',
  });
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted = process.env
    .REACT_APP_DYNAMIC_ELECTRIC_RATE
    ? `$${userPrefs.get('electricityRate')}/kWh`
    : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted;

  let salesTaxElecInfo = userPrefs.get('selectSalesTaxElecRate');
  for (let zip in salesTaxElecInfo) {
    if (location && zip === location.postcode) {
      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value = salesTaxElecInfo[zip][1];

      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted =
        '$' +
        Math.round(userPrefs.get('electricityRate') * 100000) / 100000 +
        '/kWh';
    }
  }
  // let GASOLINE_RATE_IN_DOLLARS_PER_GALLON = {
  //   title: GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title,
  //   link: GASOLINE_PRICE_IN_DOLLARS_PER_GAL.link,
  //   value: userPrefs.get("gasolinePriceInCentsPerGal")
  //     ? userPrefs.get("gasolinePriceInCentsPerGal") / 100
  //     : GASOLINE_PRICE_IN_DOLLARS_PER_GAL.value,
  //   valueAsFormatted: userPrefs.get("gasolinePriceInCentsPerGal")
  //     ? "$" + userPrefs.get("gasolinePriceInCentsPerGal") / 100 + "/gal"
  //     : GASOLINE_PRICE_IN_DOLLARS_PER_GAL.valueAsFormatted,
  // };
  return (
    <section id="CostOfOwnership">
      <div className="CostOfOwnership">
        <div className="row">
          {comparison ? null : (
            <div className={`col-sm-12 text-center`}>
              <p className={usedEv ? `compare-title p-3` : `compare-title`}>
                {title || (
                  <FormattedMessage
                    id="ev.compareGasVehicle"
                    defaultMessage="Compare the {year} {car} to a similar gas vehicle, the {gasCar}"
                    description="Description of Colton Recharged"
                    values={{
                      year: (
                        <>
                          {car['model_year']}
                        </>
                      ),
                      car: (
                        <>
                          {FormatCarName(car)}
                        </>
                      ),
                      gasCar: FormatCarName(equivalentGasVehicle),
                    }}
                  />
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="graph-title top-margin">
        <FormattedMessage
          id="graph.title.costAnalysis"
          defaultMessage="Cost analysis"
          description="Cost analysis"
        />
        <ToolTip
          message={intl.formatMessage({
            id: 'costAnalysisDisclaimer',
            defaultMessage:
              'Comparisons reflect approximate savings and are not guaranteed. Actual savings will vary based on individual use and market conditions. APS makes no representations or warranties of any kind, either express or implied, with respect to the accuracy of the information contained herein.',
          })}
          id="cost_analysis_tooltip"
        />
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: 'left' }}>
          <CostOfOwnershipOptions
            cars={cars}
            usedEv={usedEv}
            comparison={comparison}
            car={car}
            equivalentGasVehicle={equivalentGasVehicle}
          />
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: 'absolute', top: '-9999px' }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9 graph-container">
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED &&
          !isLoading &&
          insuranceDataApi &&
          maintenanceDataApi ? (
            <MonthlyFuelComparison
              cars={cars}
              comparison={comparison}
              insuranceData={insuranceDataApi}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
          !isLoading &&
          insuranceDataApi ? (
            <CostToOwnComparison
              cars={cars}
              hasExtraPadding
              comparison={comparison}
              insuranceData={insuranceDataApi}
              maintenanceData={maintenanceDataApi}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED ? (
            <FuelRangeChart car={car} />
          ) : null}
          {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH &&
          !isLoading &&
          insuranceDataApi ? (
            <BreakevenChart cars={cars} insuranceData={insuranceDataApi} />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object,
};
