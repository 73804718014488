const filterInventory = (evs, filterParams) => {
    let {
      vehicleMakeFilter,
      vehicleModelFilter,
      vehicleAgeFilter,
      // vehicleCondition,
      vehicleYearStartFilter,
      vehicleYearEndFilter,
      inventoryDealers
    } = filterParams || {};

    const allowedDealers = Object.keys(inventoryDealers || {}).filter(
        (key) => !!inventoryDealers[key]
      );;

    // if ( vehicleCondition === "New" ) {
    //   vehicleCondition = true
    // } else if (vehicleCondition ==="Used") {
    //   vehicleCondition = false
    // } 

    return evs
    .filter(
      ({ make }) => vehicleMakeFilter === "All" || vehicleMakeFilter === make
    )
    .filter(
      ({ model }) => vehicleModelFilter === "All" || vehicleModelFilter === model
    )
    // .filter(
    //   ({ vehicle_dealership_condition_new }) => vehicleCondition === "All" || vehicleCondition === vehicle_dealership_condition_new
    // )
    .filter(
      ({ model_year }) => (vehicleYearStartFilter === 'All' || model_year >= vehicleYearStartFilter) && (vehicleYearEndFilter === 'All' || model_year <= vehicleYearEndFilter)
    )
    .filter(
      ({ dealer }) =>
      allowedDealers.length === 0 ||
      allowedDealers.includes(dealer.dealer_id.toString())
    )
    .filter(
      ({model_year}) => {
        if (vehicleAgeFilter === "0-1") {
          return (new Date().getFullYear() - model_year < 2)
        }
        if (vehicleAgeFilter === "2-3") {
          return (1 < new Date().getFullYear() - model_year && new Date().getFullYear() - model_year < 4)
        }
        if (vehicleAgeFilter === "4+") {
          return (new Date().getFullYear() - model_year > 3)
        }
        return true
      }
    )

  };

  export default filterInventory;
