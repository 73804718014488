import React, { useState, useContext, useEffect } from 'react';
import { ValidateFiveDigitCode } from '../../../utils/Helpers/USPostalCodeValidator';

import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import Checkbox from '../../shared/InputElements/Checkbox';

import { FormattedMessage, useIntl } from 'react-intl';

const InputZipcodeForChargingMap = () => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get('workingZipcode')
  );
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] =
    useState(false);

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = 'input-zipcode-for-charging-map';
  const idAria = id + ' help';

  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== 'Enter') return;
    if (ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating) {
      async function asyncCall() {
        await userPrefs.set({
          workingZipcode: workingZipcode,
        });

        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall();
    }
  };

  useEffect(() => {
    userPrefs.set({
      vehicleTypeFilter: isIgnoringLowPowerStations ? 'highPower' : 'All',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIgnoringLowPowerStations]);

  return (
    <>
      <div className="map-options row">
        <div className="form-group zip-options col-lg-3">
          <div className="input-group mb-2 mr-sm-2">
          <label className="label-block">
            <FormattedMessage
              id="zipcode"
              defaultMessage="Zipcode"
              description="Zipcode"
            />
            <input
              id={id}
              className={
                isInvalid
                  ? 'form-control map-control is-invalid'
                  : 'form-control map-control'
              }
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={(e) => setWorkingZipcode(e.target.value)}
              onKeyDown={(e) => handleUpdateButtonPress(e, 'input')}
            />
              </label>
          </div>
        </div>
        <div className="form-group power-options col-lg-4">
          <Checkbox
            id="show-only-supercharging-stations-route"
            value={isIgnoringLowPowerStations}
            label="High Power Stations Only"
            handler={(e) =>
              setIsIgnoringLowPowerStations((currentValue) => !currentValue)
            }
          />
        </div>
        <div className="form-group col-lg-5">
          <button
            type="button"
            className="btn btn-aps btn-detail"
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'chargingMap.searchCharging',
                  defaultMessage: 'Update ZIP Code',
                })
              : 'Update ZIP Code'}
          </button>
        </div>
      </div>
      {(isInvalid || !ValidateFiveDigitCode(workingZipcode)) && (
        <div className="text-danger small">
          Error: {workingZipcode} is not a valid zipcode
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
