import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FaqComponent from '../../components/Faq/FaqComponent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import './FAQ.scss';

function getSortBySection(faqs) {
  let faqsSections = {};

  faqs.forEach((faq) => {
    if (!faqsSections[faq.section])
      faqsSections[faq.section] = {
        title: faq.section,
        faqs: [faq],
      };
    else {
      faqsSections[faq.section].faqs.push(faq);
    }
  });

  return faqsSections;
}

const FAQ = ({ faqs }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_FAQ_TITLE;
  });

  if (!faqs)
    return (
      <div className="spinner-container">
        <LoadingSpinner />
      </div>
    );

  const sortedWithSections = getSortBySection(faqs);

  return (
    <section className="container">
      <div className="row">
        <div className="col text-center asked-questions">
          <h1 className="title-page pad-top">
            <FormattedMessage
              id="FAQ.title"
              defaultMessage="Frequently Asked Questions"
              description="Frequently Asked Questions"
            />
          </h1>
          <h2 className="mb-bottom pad-bottom">
            <FormattedMessage
              id="FAQ.subtitle"
              defaultMessage="EVs are still new, and it’s natural to have questions. Find answers below."
              description="EVs are still new, and it’s natural to have questions. Find answers below."
            />
          </h2>
        </div>
      </div>
      <div className="row faqContainerPage">
        {Object.values(sortedWithSections).map((section) => (
          <div key={`faq-${section.title}`}>
            <h2>{section.title}</h2>
            <FaqComponent faqs={section.faqs} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;

FAQ.propTypes = {
  faqs: PropTypes.array,
};
