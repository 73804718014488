import React from "react";
import PropTypes from "prop-types";

import sortIncentives from "../../functions/incentives/Sort/sortIncentives";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "../IncentivesPreferences/IncentivePreferences"
import { FormatAsDollars, FormatCarName } from "./../../utils/Helpers/Format";
import { useIntl, FormattedMessage } from 'react-intl';

const EVIncentives = ({ car, incentives, usedEv, zipcode }) => {
  const intl = useIntl();
  if (!incentives) return null;

  incentives = incentives.filter(incentive => usedEv ? incentive.applicable_to_used_vehicles : incentive.applicable_to_new_vehicles)

  const totalSavings = sortIncentives(incentives).total("eligible-rebates");
  return (
    <section className="container-fluid EVIncentives">
      <div className="container">
        <div>
          <div className="row">
            <div className="col-12">
              <p className="mb-3 text-center compare-title">
                <FormattedMessage
                  id="ev.incentives.title"
                  defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                  description="EV Incentives Title"
                  values={{
                    savings: (
                      <strong style={{ color: '#2C5E2C', fontWeight: 800 }}>
                        {FormatAsDollars(totalSavings)}
                      </strong>
                    ),
                    lineBreak: <br />,
                    car: FormatCarName(car),
                  }}
                />
              </p>
              <div className="text-center" style={{ marginBottom: '31px' }}>
                <IncentivePreferences
                  titleText="PERSONALIZE INCENTIVES"
                  btnText={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'personalizeIncentives',
                          defaultMessage: 'Personalize Incentives',
                        })
                      : 'Personalize Incentives'
                  }
                  zipcode={zipcode}
                />
              </div>
              <IncentiveCatalog incentives={incentives} usedEv={usedEv} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array
};
