import React from 'react';
import PropTypes from 'prop-types';

import './SelectedChargingStationCard.scss';
import ChargingStationsMapMarker from '../ChargingStationsMapMarker/ChargingStationsMapMarker';
import { ROUTE_MAP_TAB_ID } from '../../../constants/mapTabs';

const SelectedChargingStationCard = ({ station, toggleWithHistory }) => {
  if (!station) return null;

  const {
    station_name: stationName,
    street_address: streetAddress,
    city,
    state,
    zip,
    station_phone: stationPhone,
  } = station;

  const handlePlanRoute = () => {
    localStorage.setItem(
      'plan-route-station',
      `${streetAddress}, ${city}, ${state}`
    );
    toggleWithHistory(ROUTE_MAP_TAB_ID);
  };

  return (
    <div className="SelectedChargingStationCard">
      <div className="map-marker">
        <ChargingStationsMapMarker key={station.id} station={station} />
        <p>{stationName}</p>
      </div>
      <p>{streetAddress}</p>
      <p>{`${city}, ${state} ${zip}`}</p>
      {stationPhone ? (
        <a href={`tel:+1${stationPhone}`}>{stationPhone}</a>
      ) : null}
      <div className="line" />
      <button className="plan-route" onClick={handlePlanRoute}>
        PLAN ROUTE
      </button>
    </div>
  );
};

export default SelectedChargingStationCard;

SelectedChargingStationCard.propTypes = {
  station: PropTypes.object,
};
