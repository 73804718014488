import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import ToolTip from '../../shared/ToolTip/ToolTip';
import { useIntl } from 'react-intl';

const ToggleChargerCost = () => {
       const intl = useIntl();
       const userPrefs = useContext(UserPrefsContext);

       const label = intl.formatMessage({
              id: 'ev.includeChargerCosts',
              defaultMessage: 'Include charger costs',
       });

       const tooltip = intl.formatMessage({
              id: "ev.includeChargerCosts.toolTip",
              defaultMessage: "Include $1,800 costs to cover purchase and installation of a level 2 home charger",
       });

       return (
              <>
                     <div className="checkbox-wrap">
                            <input type="checkbox"
                                   checked={userPrefs.get('showChargerCost')}
                                   onChange={(e) => {
                                          userPrefs.set({ showChargerCost: e.target.checked });
                                   }}
                            />
                            <label className='nouppercase'>{label}</label>
                            {tooltip && <ToolTip message={tooltip} id={"charger_costs_tooltip"} />}
                     </div>
              </>
       );
};

export default ToggleChargerCost;
