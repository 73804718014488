import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinHighPower from "../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "../../../client_customizations/assets/images/icons/charger-pin-private.png";

import ChargingPinPublicSelected from "../../../client_customizations/assets/images/icons/charger-pin-public-selected.png";
import ChargingPinHighPowerSelected from "../../../client_customizations/assets/images/icons/charger-pin-high-power-selected.png";
import ChargingPinDefaultSelected from "../../../client_customizations/assets/images/icons/charger-pin-private-selected.png";

const iconSrc = (type, isSelected) => {
  switch (type) {
    case "public":
      return isSelected ? ChargingPinPublicSelected : ChargingPinPublic;
    case "highPower":
      return isSelected ? ChargingPinHighPowerSelected : ChargingPinHighPower;
    case "private":
    default:
      return isSelected ? ChargingPinDefaultSelected : ChargingPinDefault;
  }
};
const iconSrcSelected = (type, isSelected) => {
  switch (type) {
    case "public":
      return ChargingPinPublicSelected;
    case "highPower":
      return ChargingPinHighPowerSelected;
    case "private":
    default:
      return ChargingPinDefaultSelected;
  }
};

// NOTE: refactored this into its own component to mitigate this:
// https://github.com/google-map-react/google-map-react/issues/583
const ChargingStationsMapMarker = ({
  station,
  ignoreSuperchargerStations,
  selectedStation,
  onClick,
  onMouseOver,
  isSelected,
}) => {
  let iconType =
    Number(station.ev_dc_fast_num) && !ignoreSuperchargerStations
      ? "highPower"
      : station.access_code === "public"
      ? "public"
      : "default";

  let imageStyle = {
    width: "25px",
  };
  if (selectedStation && selectedStation.id === station.id) {
    imageStyle.filter = "brightness(85%)";
  }

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)",
  };

  return (
    <span
      station={station}
      style={placeStyle}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {isSelected ? (
        <img
          src={iconSrcSelected(iconType, isSelected)}
          style={imageStyle}
          alt=""
        />
      ) : (
        <img src={iconSrc(iconType, isSelected)} style={imageStyle} alt="" />
      )}
    </span>
  );
};

ChargingStationsMapMarker.propTypes = {
  station: PropTypes.shape({
    ev_dc_fast_num: PropTypes.number,
    access_code: PropTypes.string,
  }),
  ignoreSuperchargerStations: PropTypes.bool,
  selectedStation: PropTypes.object,
  onClick: PropTypes.func,
};

ChargingStationsMapMarker.defaultProps = {
  station: {},
  ignoreSuperchargerStations: false,
  selectedStation: null,
  onClick: () => {},
};

export default ChargingStationsMapMarker;
