import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CollapsibleSection.scss';
import icon from '../../../client_customizations/assets/images/icons/accordion_arrow.svg';

/* A collapsible component that opens and closes sub-content 
    todo: have state of open ityems persist on redraw
*/

const CollapsibleSection = ({
  headLine,
  children,
  collectionOfOpenItems = [],
  updateCollectionOfOpenItemsFunction = (x) => x,
}) => {
  const [collapsibleSectionButtonOpen, setCollapsibleSectionButtonOpen] =
    useState(false);

  function handleHideSection(e) {
    e.preventDefault();
    setCollapsibleSectionButtonOpen(!collapsibleSectionButtonOpen);
    var grandParentElement =
      e.currentTarget.parentElement.parentElement.parentElement;

    if (collectionOfOpenItems.includes(grandParentElement)) {
      updateCollectionOfOpenItemsFunction([
        ...collectionOfOpenItems.filter((item) => item !== grandParentElement),
      ]);
    } else {
      updateCollectionOfOpenItemsFunction([
        ...collectionOfOpenItems,
        grandParentElement,
      ]);
    }

    if (grandParentElement.className.includes('contentHide')) {
      grandParentElement.className = grandParentElement.className.replace(
        'contentHide',
        'contentShow'
      );
    } else {
      grandParentElement.className = grandParentElement.className.replace(
        'contentShow',
        'contentHide'
      );
    }
  }
  return (
    <>
      <div className="collapsibleSection">
        <div className={`contentHide`}>
          <div className={`collapsibleSectionHead d-flex`}>
            <div className="collapsibleSectionHeadLine">{headLine}</div>
            <div className="collapsibleSectionHeadButton">
              <img
                onClick={handleHideSection}
                className={
                  collapsibleSectionButtonOpen ? 'iconOpen' : 'iconClose'
                }
                src={icon}
                alt="collapsible_icon"
              />
            </div>
          </div>
          <div className="collapsibleSectionBody">
            <div className="collapsibleSectionBodyContent">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CollapsibleSection;

CollapsibleSection.propTypes = {
  headline: PropTypes.node,
  children: PropTypes.node.isRequired,
  collectionOfOpenItems: PropTypes.array,
  updateCollectionOfOpenItemsFunction: PropTypes.func,
};
