import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EVCard from '../../../components/EVCard/EVCard';
import { FormattedMessage } from 'react-intl';
import './VehicleCarousel.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const VehicleCarousel = ({ electricVehicles, userLocation }) => {
  const [firstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles)
    return (
      <section className="container-fluid grey">
        <LoadingSpinner />
      </section>
    );

  if (!electricVehicles || electricVehicles.length <= 3) return null;
  if (!userLocation) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : 'many';

  const MNGoldDealers = new Set([
    'Mitsubishi',
    'Nissan',
    'Chevrolet',
    'Ford',
    'Volkswagen',
    'Toyota',
    'Tesla',
    'Jaguar',
    'Land Rover',
    'Volvo',
  ]);

  const COGoldDealers = new Set([
    'Audi',
    'Nissan',
    'Ford',
    'Volkswagen',
    'Hyundai',
    'Kia',
    'Tesla',
    'Jaguar',
  ]);

  const stateGoldDealers = {
    Minnesota: MNGoldDealers,
    Colorado: COGoldDealers,
  };

  if (stateGoldDealers[userLocation.region]) {
    electricVehicles = electricVehicles.filter((ev) => {
      return stateGoldDealers[userLocation.region].has(ev.make);
    });
  }

  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount,
    firstEv + 4 <= maxIndex ? firstEv + 4 : firstEv + 4 - vehicleCount,
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]],
  ];

  return (
    <section className="dicover-section white">
      <div className="dicover-container">
        <h2 className="title-head">
          <FormattedMessage
            id="homepage.vehicleCarousel.title"
            defaultMessage="There are more electric vehicles than ever."
            description="Vehicle Carousel Title"
          />
          <span className="title-head-discover">
            {' '}
            <FormattedMessage
              id="homepage.vehicleCarousel.title.discover"
              defaultMessage="Discover yours."
              description="Vehicle Carousel Title Discover"
            />
          </span>
        </h2>
        <h3 className="title-sub">
          <FormattedMessage
            id="homepage.vehicleCarousel.subTitle"
            defaultMessage="Compare the cost of your selection to a similar gas vehicle. An electric car can save you money {lineBreak}because it can be less expensive to own and maintain than a 100% gasoline-powered car."
            description="Vehicle Carousel Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </h3>
        <Link to="/compare-vehicles" className="btn btn-aps" role="button">
          <FormattedMessage
            id="homepage.vehicleCarousel.compareVehiclesButton"
            defaultMessage="Compare Vehicles"
            description="Compare Vehicles"
          />
        </Link>
      </div>

      <div className="car-list">
        {filteredEVs.map((ev, i) => (
          <EVCard
            ev={ev}
            hasEvDetails={true}
            hasIncentivesAndMatchScore={true}
          />
        ))}
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
};
