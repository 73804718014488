import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import { FormattedMessage, useIntl } from 'react-intl';

const InputElectricityRate = () => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const label = intl.formatMessage({
    id: 'ev.electricityRate',
    defaultMessage: 'ELECTRICITY RATE ($/kWh)',
  });
  return (
    <>
      <label>{label}</label>

      <div className="currency-wrap">
        <span className="currency-code">$</span>
        <input
          value={Math.round(userPrefs.get('electricityRate')) / 100}
          className="text-currency form-control form-control-input"
          type="number"
          placeholder="0.0001"
          step={0.0001}
          min={0}
          max={100}
          maxLength={5}
          onChange={(e) => {
            if (e.target.value < 10) {
              const decimalRegex = /\.(\d{5,})$/;
              if (!decimalRegex.test(e.target.value.toString())) {
                userPrefs.set({ electricityRate: e.target.value * 100});
              }
            }
          }}
          lang="en-150"
        />
        <span className="unit-code">/ kWh</span>
      </div>

      <p>
        <FormattedMessage
          id="ev.electricityRateCalc"
          defaultMessage="Electricity rate is calculated as the average for six month of summer off-peak and six months off-peak for Rate A, the cheapest rate available for your zip code."
          description="Electricicty Rate Text"
        />
      </p>
    </>
  );
};

export default InputElectricityRate;
