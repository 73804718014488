import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import VehicleImage from '../../../components/VehicleImage/VehicleImage';
import PricePanels from '../../../components/EVJumbotron/PricePanels/PricePanels';
import { FormatAsDollars, FormatCarName } from '../../../utils/Helpers/Format';
import { FormattedMessage } from 'react-intl';
import UsedVehiclePanels from '../../../components/EVJumbotron/UsedVehiclePanels/UsedVehiclePanels';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import vector from '../../../client_customizations/assets/images/icons/vector.svg';
import SmoothScroll from '../../../utils/Helpers/SmoothScroll';
import CarDetailsTable from '../../../components/EVJumbotron/CarDetailsTable/CarDetailsTable';

const EVJumbotron = ({ car, paymentDetails, cars, setUsedEv }) => {
  const userPrefs = React.useContext(UserPrefsContext);

  if (!car) return null;

  const carLinks = {
    Audi: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Audi',
    Buick:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Buick',
    BMW: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=BMW',
    Cadillac:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Cadillac',
    Chevrolet:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Chevrolet',
    Chrysler:
      'https://www.kengarff.com/new-vehicles/chrysler/?_dFR%5Bfueltype%5D%5B0%5D=Hybrid%2520Fuel&_dFR%5Bin_utah%5D%5B0%5D=1&_dFR%5Bmake%5D%5B0%5D=Chrysler&_dFR%5Btype%5D%5B0%5D=New',
    Dodge:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Dodge',
    Fiat: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Fiat',
    Ford: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Ford',
    GMC: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=GMC',
    Honda:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Honda',
    Hyundai:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Hyundai',
    Jaguar:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Jaguar',
    Jeep: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Jeep',
    Kia: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Kia',
    'Land Rover':
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Land-Rover',
    Lexus:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Lexus',
    Maserati:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Maserati',
    Mercedes:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Mercedes-Benz',
    Nissan:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Nissan',
    Porsche:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Porsche',
    Ram: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Ram',
    Rivian:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Rivian',
    Subaru:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Subaru',
    Tesla:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Tesla',
    Toyota:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Toyota',
    VW: 'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Volkswagen',
    Volvo:
      'https://www.kengarff.com/electric-vehicles/?_dFR%5Bfueltype%5D%5B0%5D=Electric&_dFR%5Bfueltype%5D%5B1%5D=Electric%2520Fuel%2520System&_dFR%5Bmake%5D%5B0%5D=Volvo',
  };

  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value
  let imagesSrc =
    car && car['images']
      ? car.images.map((n) =>
          n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n]
        )
      : [];

  //Flatten evImgs into a single array
  const images = [].concat.apply([], imagesSrc);
  let carImages = images.map((image) => {
    return {
      original: image.url_full,
      thumbnail: image.url_thumbnail,
      originalAlt: image.alt,
      thumbnailAlt: image.title,
      legalInfo: image.legal_info,
    };
  });
  carImages.shift();

  const handleClick = (make, model) => {
    userPrefs.set({
      vehicleMakeFilter: make,
      vehicleModelFilter: model,
      inventoryDealers: {},
    });
  };

  return (
    <>
      <section className="container-fluid" id="ev-jumbotron-title">
        <div className="container evContainer">
          <div className="row">
            <div className="col">
              <a className="backButton" href="/vehicles">
                <img src={vector} alt="back-button"></img>
                <FormattedMessage
                  id="ev.carDetails.view.back"
                  defaultMessage="ALL VEHICLES"
                  description="ALL VEHICLES"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h1>
                {setUsedEv ? (
                  <FormattedMessage
                    id="ev.usedCarTitle"
                    defaultMessage="Used {car}"
                    description="Used Car Title"
                    values={{
                      car: FormatCarName(car),
                    }}
                  />
                ) : (
                  FormatCarName(car)
                )}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>
                <FormattedMessage
                  id="ev.carDetails.view.subtitle"
                  defaultMessage="MSRP - {msrp}"
                  description="msrp"
                  values={{
                    msrp: FormatAsDollars(car.msrp),
                  }}
                />
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                <VehicleImage image={images[0]} size="full" />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
              <PricePanels car={car} paymentDetails={paymentDetails} />
              <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv} />
              <br />
              {setUsedEv ? null : (
                <div className="containerPaymentButtons">
                  <Link to="/incentives">
                    <button className="btn btn-aps-secondary btn-100">
                      <FormattedMessage
                        id="ev.jumbotron.maximizeIncentives"
                        defaultMessage="MAXIMIZE INCENTIVES"
                        description="maximize incentives"
                      />
                    </button>
                  </Link>
                  <button
                    className="btn btn-aps-secondary btn-100"
                    onClick={(_) => SmoothScroll('CostOfOwnership')}
                  >
                    <FormattedMessage
                      id="ev.jumbotron.totalCost"
                      defaultMessage="SEE TOTAL COST OF OWNERSHIP"
                      description="total cost"
                    />
                  </button>
                  <Link
                    to="/compare-vehicles"
                    onClick={() => handleClick(car.make, car.model)}
                  >
                    <button className="btn btn-aps-secondary btn-100">
                      <FormattedMessage
                        id="ev.jumbotron.compare"
                        defaultMessage="COMPARE TO OTHER VEHICLES"
                        description="Compare"
                      />
                    </button>
                  </Link>
                  <a
                    href={
                      car?.make in carLinks
                        ? carLinks[car?.make]
                        : 'https://www.kengarff.com/electric-vehicles/'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleClick(car.make, car.model)}
                  >
                    <button className="btn btn-aps btn-100">
                      <FormattedMessage
                        id="ev.jumbotron.localInventory"
                        defaultMessage="SHOP LOCAL INVENTORY"
                        description="local"
                      />
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="carDetailContainer">
        <div className="container">
          <CarDetailsTable car={car} />
        </div>
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
