import React from 'react';
import './Footer.scss';
import FacebookIcon from '../../../assets/images/icons/Facebook.svg';
import InstagramIcon from '../../../assets/images/icons/Instagram.svg';
import XIcon from '../../../assets/images/icons/X.svg';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

import logo from '../../../assets/images/gowise_logo.svg';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="Footer">
      <div className="social-media-row">
        <a
          href="https://www.facebook.com/KenGarffAuto/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-media-icon"
            alt="Facebook Icon"
            src={FacebookIcon}
          />
        </a>
        <a
          href="https://twitter.com/kengarff"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="social-media-icon" alt="Facebook Icon" src={XIcon} />
        </a>
        <a
          href="https://www.instagram.com/kengarff/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-media-icon"
            alt="Linkedin Icon"
            src={InstagramIcon}
          />
        </a>
      </div>

      <div className="legal-info">
        <span className="subtext">
          Copyright &copy; {year} {process.env.REACT_APP_FULL_COMPANY_NAME}.{' '}
        </span>
        <span className="subtext">All rights reserved. </span>
        <span className="disclaimer_container">
              <DisclaimerComponent
                clientName="zappynobackground"
                textStyle={{
                  fontFamily: "Futura PT",
                  fontSize: "14px",
                }}
                imageStyle={{marginTop:"30px", maxWidth: "100%", marginBottom: '30px'}}
                wrapperStyle={{
                  padding: "0px",
                }}
              />
        </span>
      </div>

      <img
        src={logo}
        alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
        className="img-fluid"
        style={{ maxWidth: '190px' }}
      />
    </div>
  );
};

export default Footer;
