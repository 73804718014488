import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LinkCard from '../../LinkCard/LinkCard';

const HomepageBigPromise = () => {
  return (
    <section className="white">
      <div className="homepage-jumbotron">
        <h1 className="title-banner">
          <FormattedMessage
            id="homepage.banner.title"
            defaultMessage="Your ev questions answered by experts who "
            description="Your ev questions answered by experts who "
          />
          <span className="title-banner-end">
            <FormattedMessage
              id="homepage.banner.title.end"
              defaultMessage="Listen."
              description="Listen."
            />
          </span>
        </h1>
        <div className="banner-subsection">
          <span className="banner-subsection-text">
            <FormattedMessage
              id="homepage.banner.title.ends"
              defaultMessage="Estimate and compare costs, savings, incentives & more."
              description="Estimate and compare costs, savings, incentives & more."
            />
          </span>

          <Link to={'/vehicles'} className="btn btn-aps btn-detail">
            <FormattedMessage
              id="homepage.banner.button.text"
              defaultMessage="FIND YOUR EV"
              description="FIND YOUR EV"
            />
          </Link>
        </div>
      </div>
      <div className="link-card-container">
        <LinkCard type="BROWSE_VEHICLES" />
        <LinkCard type="COMPARE_VEHICLES" />
        <LinkCard type="CHARGING_STATIONS" />
        <LinkCard type="DISCOVER_INCENTIVES" />
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string,
};
