import React from 'react';
import PropTypes from 'prop-types';

import './LinkCard.scss';

import BROWSE_VEHICLES from '../../assets/images/icons/shopElectricIcon.svg';
import COMPARE_VEHICLES from '../../assets/images/icons/compareIcon.svg';
import DISCOVER_INCENTIVES from '../../assets/images/icons/discoverIncentivesIcon.svg';
import CHARGING_STATIONS from '../../assets/images/icons/findStationsIcon.svg';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const LinkCard = ({ type }) => {
  const intl = useIntl();
  let src, link, button_title;

  switch (type) {
    case 'BROWSE_VEHICLES':
      src = BROWSE_VEHICLES;
      link = '/vehicles';
      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.browse',
            defaultMessage: 'Shop Electric',
          })
        : 'Discover EVs';
      break;
    case 'COMPARE_VEHICLES':
      src = COMPARE_VEHICLES;
      link = '/compare-vehicles';

      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.compare',
            defaultMessage: 'Compare Vehicles',
          })
        : 'Compare Vehicles';
      break;
    case 'DISCOVER_INCENTIVES':
      src = DISCOVER_INCENTIVES;
      link = '/incentives';
      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.incentives',
            defaultMessage: 'Discover Incentives',
          })
        : 'Discover Incentives';
      break;
    case 'CHARGING_STATIONS':
      src = CHARGING_STATIONS;
      link = '/charging-stations';
      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.charging',
            defaultMessage: 'Find Charging Stations',
          })
        : 'Find Charging Stations';
      break;

    default:
      return null;
  }

  return (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <img src={src} alt="" />
        <button className="btn btn-homepage">{button_title}</button>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string,
};
