import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../../hooks/useMapTabs';
import { FormattedMessage } from 'react-intl';

const MapPage = ({
  userLocation,
  ip,
  uuid,
  history,
  dealerLocations,
  tabId,
  title,
  zipcode,
  electricVehicles,
  usedElectricVehicles,
  homePage,
  evPage,
}) => {
  useEffect(() => {
    document.title =
      tabId === 'tabbed-maps-route-tab'
        ? 'Route Planner | Go Wise EV Shopper'
        : 'Charging Stations | Go Wise EV Shopper';
  });

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = (newTabId) => {
    const newTab = findTab(newTabId);
    if (!homePage) {
      history.push(newTab.url);
    }
    toggle(newTab.id);
  };

  const home = homePage || evPage;

  return (
    <section
      className={
        homePage || evPage ? `container-home white map home-map-section-container` : `container-fluid home-map-section-container`
      }
    >
       <div className='home-map-section'>
       <div
              className={
              ( home ? 'max-width ' : 'align-items ') + 'homepage-titles-container '
              }
       >
              <h1 className={home ? 'primary' : 'title-map'}>
              <FormattedMessage
              id={(home ? 'homepage' : 'page') + '.chargingMap.title'}
              defaultMessage="Recharge on the go, <highline>wherever you go.</highline>"
              description="charge map title"
              values={{
                     highline: (chunks) => <span className="secondary">{chunks}</span>,
              }}
              />
              </h1>
              <p className={home ? 'HomepageSubTitle' : 'subtitle-map'}>
              <FormattedMessage
              id={(home ? 'homepage' : 'page' )+ '.chargingMap.subtitle'}
              defaultMessage="subtitle"
              description="charge map subtitle"
              />
              </p>
       </div>

       <TabbedMaps
              toggle={toggleWithHistory}
              activeTabId={activeTabId}
              userLocation={userLocation}
              dealerLocations={dealerLocations}
              zipcode={zipcode}
              electricVehicles={electricVehicles}
              usedElectricVehicles={usedElectricVehicles}
       />
      </div>
    </section>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
