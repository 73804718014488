import { useState } from "react";

const useMapBounds = () => {
  const [bounds, setBounds] = useState({
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
  });

  const [center, setCenter] = useState({
    lat: undefined,
    lng: undefined,
  });

  const registerMapBoundsListeners = (map) => {
    const updateMapBounds = () => {
      const bounds = map.getBounds();
      const mapCenter = map.getCenter();
      setBounds({
        top: bounds.getNorthEast().lat(),
        right: bounds.getNorthEast().lng(),
        bottom: bounds.getSouthWest().lat(),
        left: bounds.getSouthWest().lng(),
      });

      setCenter({
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      });
    };

    map.addListener("idle", updateMapBounds);
    map.addListener("tilesloaded", updateMapBounds);
  };

  const withinCurrentBounds = (lat, lng) => {
    const { top, bottom, left, right } = bounds;

    if (!top || !bottom || !left || !right) {
      return false;
    }
    if (top >= lat && lat >= bottom) {
      if (left <= right && left <= lng && lng <= right) {
        return true;
      } else if (left > right && (left <= lng || lng <= right)) {
        return true;
      }
    }
    return false;
  };

  const filterWithinBounds = (things) => {
    return (things || []).filter((thing) => {
      const lat = thing.latitude || thing.lat;
      const lng = thing.longitude || thing.lng;
      return lat && lng ? withinCurrentBounds(lat, lng) : false;
    });
  };

  return {
    bounds,
    center,
    filterWithinBounds,
    registerMapBoundsListeners,
    withinCurrentBounds,
  };
};

export default useMapBounds;
