import React from 'react';
import PropTypes from 'prop-types';

import { FormatAsTime } from './../../../utils/Helpers/Format';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from './Table';
import './CarDetailsTable.scss';

const getWeightClass = (gvwr) => {
  switch (true) {
    case gvwr < 6001:
      return '1';
    case gvwr > 6000 && gvwr < 10001:
      return '2';
    case gvwr > 10000 && gvwr < 14001:
      return '3';
    case gvwr > 14000 && gvwr < 16001:
      return '4';
    case gvwr > 16000 && gvwr < 19501:
      return '5';
    case gvwr > 19500 && gvwr < 26001:
      return '6';
    case gvwr > 26000 && gvwr < 33001:
      return '7';
    case gvwr > 33000:
      return '8';
    default:
      return '';
  }
};

const CarDetailsTable = ({ car }) => {
  const intl = useIntl();

  const col1 = [
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.type',
        defaultMessage: 'Type',
      }),
      value: car.type,
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.subtipe',
        defaultMessage: 'Subtype',
      }),
      value: car.form_factor,
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.gvwr',
        defaultMessage: 'GVWR',
      }),
      value: (
        <FormattedMessage
          id="vehicle.gvwr.value"
          defaultMessage="{value} lbs"
          description=" lbs"
          values={{
            value: car.curb_weight,
          }}
        />
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.weightClass',
        defaultMessage: 'Weight Class',
      }),
      value: getWeightClass(car.curb_weight),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.drivetype',
        defaultMessage: 'Drivetype',
      }),
      value: car.drivetrain,
    },
  ];

  const col2 = [
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.fuelType',
        defaultMessage: 'FuelType',
      }),
      value: car.fuel === 'PHEV' ? 'Electric / Gas' : 'Electric',
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.transmission',
        defaultMessage: 'Transmission',
      }),
      value: intl.formatMessage({
        id: 'vehicle.transmission.automatic',
        defaultMessage: 'Automatic',
      }),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.payload',
        defaultMessage: 'Payload',
      }),
      value: '',
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.seats',
        defaultMessage: 'Seats',
      }),
      value: car.seats_max,
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.cargoSpace',
        defaultMessage: 'Cargo Space',
      }),
      value: (
        <FormattedMessage
          id="vehicle.cargoSpace.value"
          defaultMessage="Cargo Space"
          description="Cargo Space"
          values={{
            cargoSpace: car.cargo_volume_max,
          }}
        />
      ),
    },
  ];

  const col3 = [
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.batteryCapacity',
        defaultMessage: 'Battery Capacity',
      }),
      value: (
        <FormattedMessage
          id="vehicle.batteryCapacity.value"
          defaultMessage="{batteryCapacity} kWh"
          description=" battery capacity"
          values={{
            batteryCapacity: car.battery_capacity,
          }}
        />
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.electricEfficiency',
        defaultMessage: 'Electric Efficiency',
      }),
      value: (
        <FormattedMessage
          id="vehicle.electricEfficiency.value"
          defaultMessage="{electricEfficiency} MPGe"
          description=" electric efficiency value"
          values={{
            electricEfficiency: ((car.electric_efficiency / 100) * 40).toFixed(
              1
            ),
          }}
        />
      ),
    },
    {
      show: ['PHEV'],
      title: intl.formatMessage({
        id: 'vehicle.gasEfficiency',
        defaultMessage: 'Gas Efficiency',
      }),
      value: (
        <FormattedMessage
          id="vehicle.gasEfficiency.value"
          defaultMessage="{gasEfficiency} MPG"
          description="gas efficiency value"
          values={{
            gasEfficiency: car.fossil_fuel_efficiency,
          }}
        />
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.electricRange',
        defaultMessage: 'Electric Range',
      }),
      value: (
        <FormattedMessage
          id="vehicle.electricRange.value"
          defaultMessage="{electricRange} miles"
          description="electric range value"
          values={{
            electricRange: car.electric_range,
          }}
        />
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.totalRange',
        defaultMessage: 'Total Range',
      }),
      value: (
        <FormattedMessage
          id="vehicle.totalRange.value"
          defaultMessage="{totalRange} miles"
          description="total range value"
          values={{
            totalRange: car.total_range,
          }}
        />
      ),
    },
  ];

  const col4 = [
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.maxACChargeRate',
        defaultMessage: 'Max AC Charge Rate',
      }),
      value: (
        <FormattedMessage
          id="vehicle.maxACChargeRate.value"
          defaultMessage="{maxACChargeRate} kW"
          description="max AC charge rate value"
          values={{
            maxACChargeRate: car.ac_charging_power,
          }}
        />
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.maxDCChargeRate',
        defaultMessage: 'Max DC Charge Rate',
      }),
      value: car.dc_charging_power ? (
        <FormattedMessage
          id="vehicle.maxDCChargeRate.value"
          defaultMessage="{maxDCChargeRate} kW"
          description="max DC charge rate value"
          values={{
            maxDCChargeRate: car.dc_charging_power,
          }}
        />
      ) : (
        '-'
      ),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.chargeTimeLevel2',
        defaultMessage: 'Charge Time - Level 2',
      }),
      value: '~ ' + FormatAsTime(car.battery_capacity / car.ac_charging_power),
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.chargeTimeDC',
        defaultMessage: 'Charge Time - DC',
      }),
      value: car.dc_charging_power
        ? '~ ' + FormatAsTime(car.battery_capacity / car.dc_charging_power)
        : '-',
    },
    {
      show: ['PHEV', 'BEV'],
      title: intl.formatMessage({
        id: 'vehicle.plugTypes',
        defaultMessage: 'Plug Types',
      }),
      value: car.plug_types.map((type, i) =>
        i === car.plug_types.length - 1 ? type : `${type}, `
      ),
    },
  ];

  const data = [col1, col2, col3, col4];

  const pureData = data.map((colum) =>
    colum.filter((item) => item.show.includes(car.fuel))
  );

  return (
    <div className="carDetailsTable">
      <p className="title">
        <FormattedMessage
          id="ev.carDetails.table.title"
          defaultMessage="Vehicle Specs"
          description="vehicle specs label"
        />
      </p>
      <Table data={pureData} />
      <p className="carDetailsFooter">
        <FormattedMessage
          id="ev.carDetails.table.footer"
          defaultMessage="Vehicle Detail Footer"
          description="Vehicle Detail Footer"
          values={{
            br: <br />,
          }}
        />
      </p>
    </div>
  );
};

export default CarDetailsTable;

CarDetailsTable.propTypes = {
  car: PropTypes.object,
};
