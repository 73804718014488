import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';
import EVCard from '../../../components/EVCard/EVCard';
import sortEVs from './../../../functions/vehicle/Sort/sortEVs';
import filterEVs from '../../../functions/vehicle/Filter/filterEVs';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import getLocalVehicles from '../../../functions/vehicle/getLocalVehicles';
import './EVCatalog.scss';

const EVCatalog = ({
  vehicles,
  hasIncentivesAndMatchScore,
  hasEvDetails,
  hasLinktoEv,
}) => {
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    userPrefs.set({ vehicleMakeFilter: 'All' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!vehicles) return <LoadingSpinner />;
  const electricVehicles = userPrefs.get('locallyAvailableVehicleFilter')
    ? getLocalVehicles(vehicles)
    : vehicles;

  const sortedEvs = sortEVs(
    electricVehicles,
    userPrefs.get('vehicleSortType'),
    userPrefs.get('vehicleSortDirection'),
    userPrefs.get('milesDrivenDaily'),
    userPrefs.get('chargerLevel'),
    userPrefs.get('maxBudget'),
    userPrefs.get('minSeats'),
    userPrefs.get('salesTax'),
    userPrefs.get('downPayment')
  );

  const filteredAndSortedElectricVehicles = filterEVs(sortedEvs, {
    vehicleFormFactorFilters: userPrefs.get('vehicleFormFactorFilters'),
    vehicleFuelTypeFilters: userPrefs.get('vehicleFuelTypeFilters'),
    vehicleMakeFilter: userPrefs.get('vehicleMakeFilter'),
    vehicleMinSeatsFilter: userPrefs.get('minSeats'),
    vehicleRangeFilter: userPrefs.get('milesDrivenDaily'),
    vehicleBudgetFilter: userPrefs.get('maxBudget'),
    vehicleAvailableNowFilter: userPrefs.get('vehicleAvailableNowFilter'),
  });

  if (!filteredAndSortedElectricVehicles.length) {
    return (
      <div className="text-center">
        <p className="h4">
          <FormattedMessage id="noResults" defaultMessage="No vehicles found" />
        </p>
      </div>
    );
  }

  return (
    <div className="catalog-cards-container">
      {filteredAndSortedElectricVehicles.map((ev) => (
        <EVCard
          ev={ev}
          hasEvDetails={hasEvDetails}
          hasIncentivesAndMatchScore={hasIncentivesAndMatchScore}
          hasLinktoEv={hasLinktoEv}
        />
      ))}
    </div>
  );
};

export default EVCatalog;

EVCatalog.propTypes = {
  vehicles: PropTypes.array,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinktoEv: PropTypes.bool,
};
