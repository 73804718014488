import React from 'react';
import { FormattedMessage } from 'react-intl';

const HomepageHomeChargerBanner = () => {
  return (
    <section className="homepage-home-charger-banner">
      <div className="homepage-home-charger-title-container">
        <h2 className="homepage-home-charger-title">
          <FormattedMessage
            id="homepage.chargerBanner.title"
            defaultMessage="Need a Home charger? <highline>We’ve got you covered.</highline>"
            description="Homepage charger Title"
            values={{
              highline: (chunks) => <span className="highline">{chunks}</span>,
            }}
          />
        </h2>
        <p className="homepage-home-charger-title-sub">
          <FormattedMessage
            id="homepage.chargerBanner.subTitle"
            defaultMessage="Get a Level 2 charger installed by a professional electrician."
            description="Homepage charger Sub Title"
          />
        </p>
        <a
          href="https://gowise.com/home-charging"
          className="btn btn-aps white"
          role="button"
          style={{maxWidth: '200px'}}
        >
          <FormattedMessage
            id="homepage.chargerBanner.chargerButton"
            defaultMessage="GET A CHARGER"
            description="charger button"
          />
        </a>
      </div>
    </section>
  );
};

export default HomepageHomeChargerBanner;
