import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsPercentRounded } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const descriptionFn = val => FormatAsPercentRounded(val);

const SlideElectricMilesPortionForPhev = ({
  id = "portion-electric-for-phev-range",
  description = descriptionFn,

  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.electricPortion", defaultMessage: "Portion Electric for PHEV"})
  const tooltip = intl.formatMessage({
    id: "electricMilesDriven",
    defaultMessage:
      "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
  });

  return (
    <Range
      id={id}
      value={userPrefs.get("electricMilesPortionForPhev")}
      label={label}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      tooltip={tooltip}
      handler={e =>
        userPrefs.set({ electricMilesPortionForPhev: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideElectricMilesPortionForPhev;

SlideElectricMilesPortionForPhev.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
