import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FaqComponent from '../../../../components/Faq/FaqComponent';

const HomepageFaqs = ({ faqs }) => {
  return (
    <section className="HomepageFaq">
      <div className="homepage-titles-container ">
        <h1 className="primary">
          <FormattedMessage
            id="homepage.faq.title.questions"
            defaultMessage="EV QUESTIONS, <highline>ANSWERED</highline>."
            description="EV QUESTIONS, "
            values={{
              highline: (chunks) => <span className="secondary">{chunks}</span>,
            }}
          />
        </h1>

        <p className="HomepageSubTitle">
          <FormattedMessage
            id="homepage.faq.subtitle"
            defaultMessage="Find answers to common EV questions about charging, incentives, vehicles, and more."
            description="subtitle."
          />
        </p>
      </div>
      <div className="faqContainerHomePage">
        <FaqComponent faqs={faqs} />
      </div>

      <a href="https://gowise.com/blog/" className="btn btn-aps" role="button">
        <FormattedMessage
          id="homepage.faq.learnMore"
          defaultMessage="LEARN MORE"
          description="learn more button"
        />
      </a>
    </section>
  );
};

export default HomepageFaqs;

HomepageFaqs.propTypes = {
  faqs: PropTypes.array,
};
