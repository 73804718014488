import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IncentiveCatalog from './../../components/IncentiveCatalog/IncentiveCatalog';
// import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import IncentiveSortControls from '../../client_customizations/components/IncentiveSortControls/IncentiveSortControls';
import IncentiveScoreOptions from '../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions';
import IncentiveVehicleOptions from '../../client_customizations/components/IncentiveVehicleOptions/IncentiveVehicleOptions';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import { FormattedMessage } from 'react-intl';

import './Incentives.scss';

const Incentives = ({ electricVehicles, incentives, userLocation }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  const renderOptions = (
    <>
      <IncentiveScoreOptions userLocation={userLocation} />
      <br />
    </>
  );

  const renderVehicleOptions = electricVehicles ? (
    <IncentiveVehicleOptions electricVehicles={electricVehicles} />
  ) : null;

  return (
    <>
      <section className="container-fluid" id="incentives">
        <div className='container'>
          <div className="row mb-3">
            <div className="col-sm-12 text-center">
              <h1 className="evHead">
                <FormattedMessage
                  id="incentives.title"
                  defaultMessage="Electric vehicle incentives"
                  description="Electric vehicle incentives"
                />
              </h1>
              <p className="lead">
                <FormattedMessage
                  id="incentives.subTitle"
                  defaultMessage="You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Adjust the filters to personalize your results."
                  description="Description of Platte River"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <IncentiveSortControls />
              </div>
            </div>
          </div>
          <div className="row main-container">
            <div className="col-xl-3 col-lg-4 d-block">
              {renderOptions}
              {renderVehicleOptions}
              <p className="disclaimer">
                <FormattedMessage
                  id="incentives.disclaimer"
                  defaultMessage="Incentive eligibility and amounts do not constitute tax or legal
              advice. Consult the incentive grantor and/or your tax or legal
              professional to determine eligibility, specific amount of
              incentives or rebates available."
                  description="Electric vehicle incentives"
                />
              </p>
            </div>
            <div
              className="col-xl-9 col-lg-8 col-sm-12"
              style={{ paddingLeft: 10 }}
            >
              {renderIncentives}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
